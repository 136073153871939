<template>
	<div class="address_box">
		<el-card class="box-card">
			<div slot="header" class="clearfix title"><span>收货地址</span></div>
			<div class="already">
				<el-card class="box-card defaltAddress" v-if="defaltAddress">
					<div slot="header" class="clearfix">
						<span>{{ defaltAddress.real_name }}</span>
						<el-link style="float: right; padding: 3px 0" type="text">{{ defaltAddress.phone }}</el-link>
					</div>
					<div class="text item">{{ defaltAddress.province + defaltAddress.city + defaltAddress.district }}</div>
					<div class="text item">{{ defaltAddress.detail }}</div>
					<div class="font-color">已上传身份证正反面</div>
				</el-card>
				<div class="addAddress" @click="dialogAddressVisible = true">新建收货地址</div>
			</div>
		</el-card>
		<!-- 订单商品 -->
		<el-card class="box-card orderGoods">
			<div slot="header" class="clearfix title"><span>订单商品</span></div>
			<el-table :data="orderGroupInfo.cartInfo" border style="width: 100%">
				<el-table-column label="商品" width="500">
					<template slot-scope="scope">
						<div class="goods">
							<img :src="scope.row.productInfo.image" />
							<div class="content">
								<div class="name">
									<span class="dot wanshui_dot">{{ trade_name }}</span>
									{{ scope.row.productInfo.store_name }}
								</div>
								<div class="attr">
									<span>{{ scope.row.productInfo.attrInfo.suk }}</span>
								</div>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column align="center" label="规格价">
					<template slot-scope="scope">
						{{ scope.row.productInfo.attrInfo ? scope.row.productInfo.attrInfo.price : scope.row.productInfo.price }}
					</template>
				</el-table-column>
				<el-table-column align="center" prop="cart_num" label="规格数量"></el-table-column>
			</el-table>
			<div class="mark">
				<div>备注信息</div>
				<textarea placeholder="请添加备注（150字以内）" v-model="mark"></textarea>
			</div>
			<div class="btn-box">
				<div class="section">
					会员优惠：
					<span>- {{ orderGroupInfo.priceGroup.vipPrice }}</span>
				</div>
				<div class="section">
					税费：
					<span>+ {{ orderGroupInfo.priceGroup.payCrossBorder }}</span>
				</div>
				<div class="section">
					运费：
					<span>{{ orderGroupInfo.priceGroup.storePostage > 0 ? '+ ￥' + orderGroupInfo.priceGroup.storePostage : '免运费' }}</span>
				</div>
				<div class="section">
					商品总金额：
					<span>￥ {{ orderGroupInfo.priceGroup.totalPrice }}</span>
				</div>
				<div class="total">
					应付总额：
					<span>￥ {{ TotalPrice }}</span>
				</div>
				<div class="addAddress" @click="paymentTap">提交订单</div>
			</div>
		</el-card>
		<!-- 收货地址弹窗 -->
		<el-dialog title="选择收货地址" :visible.sync="dialogAddressVisible" class="addressDialog">
			<el-dialog width="30%" :title="innerTitle" :visible.sync="innerVisible" append-to-body @close="innerVisibleClose">
				<New-address ref="addOrEdit" :id="id" :district="district" @innerVisibleClose="innerVisibleClose" @saveAddress="saveAddress"></New-address>
			</el-dialog>
			<el-button @click="newAddressVisible" class="newBtn">新建地址</el-button>
			<el-table :data="addressList" class="listTable">
				<el-table-column label="选择" width="55" align="center">
					<template slot-scope="scope">
						<el-radio class="radio" :label="scope.$index" v-model="radio" @change.native="getCurrentRow(scope.$index, scope.row)">*</el-radio>
					</template>
				</el-table-column>
				<el-table-column align="center" prop="real_name" label="收件人姓名" width="100"></el-table-column>
				<el-table-column align="center" prop="phone" label="手机号"></el-table-column>
				<el-table-column label="所在地区">
					<template slot-scope="scope">
						{{ scope.row.province + scope.row.city + scope.row.district }}
					</template>
				</el-table-column>
				<el-table-column prop="detail" label="详细地址"></el-table-column>
				<el-table-column prop="idcard" label="身份证号码"></el-table-column>
				<!-- 				<el-table-column label="身份证是否上传" width="50">
					<template slot-scope="scope">
						{{ scope.row }}
					</template>
				</el-table-column> -->
				<el-table-column align="center" label="是否设为默认">
					<template slot-scope="scope">
						{{ scope.row.is_default == 1 ? '默认' : '/' }}
					</template>
				</el-table-column>
				<el-table-column align="center" label="操作">
					<template slot-scope="scope">
						<span class="operate" @click="editAddress(scope.row.id)">编辑</span>
						<span class="operate del" @click="delAddress(scope.$index, scope.row.id)">删除</span>
					</template>
				</el-table-column>
			</el-table>
		</el-dialog>
	</div>
</template>
<script>
import { orderConfirm, getAddressRemove, getAddressList, getCity, createOrder } from '@api/user';
import NewAddress from '@components/NewAddress';
export default {
	name: 'OrderSubmission',
	components: {
		NewAddress
	},
	data() {
		return {
			trade_name: '',
			orderGroupInfo: {
				priceGroup: {}
			},
			dialogAddressVisible: false,
			innerVisible: false,
			district: [],
			innerTitle: '新建收货地址',
			id: 0,
			addressList: [],
			radio: false,
			defaltAddress: null,
			defaltAddressId: '',
			mask: '',
			payType: 'weixin',
			useIntegral: false,
			usableCoupon: {}
		};
	},
	computed: {
		TotalPrice() {
			let that = this;
			return parseFloat(that.orderGroupInfo.priceGroup.totalPrice) + parseFloat(that.orderGroupInfo.priceGroup.storePostage);
		}
	},
	mounted: function() {
		let that = this;
		that.trade_name = that.$route.query.trade_name;
		that.getCartInfo();
		that.getAddressList();
		that.getCityList(); // 获取城市列表
	},
	methods: {
		getCurrentRow(index, obj) {
			let that = this;
			that.radio = index;
			that.defaltAddress = obj;
			that.defaltAddressId = obj.id;
			that.dialogAddressVisible = false;
		},
		getCityList: function() {
			let that = this;
			getCity().then(res => {
				that.district = res.data;
			});
		},
		/**
		 * 删除地址
		 */
		delAddress: function(index, id) {
			let that = this;
			getAddressRemove(id).then(function() {
				that.$dialog.toast({
					mes: '删除成功!',
					callback: () => {
						that.addressList.splice(index, 1);
						that.$set(that, 'addressList', that.addressList);
					}
				});
			});
		},
		// 新建地址显示
		newAddressVisible() {
			let that = this;
			that.innerTitle = '新建收货地址';
			that.id = 0;
			that.innerVisible = true;
		},
		/**
		 * 编辑地址
		 */
		editAddress: function(index) {
			let that = this;
			that.innerTitle = '查看收货地址';
			that.id = index;
			that.innerVisible = true;
		},
		innerVisibleClose() {
			let that = this;
			that.innerVisible = false;
		},
		saveAddress() {
			let that = this;
			that.innerVisible = false;
			that.getAddressList();
		},
		getAddressList: function() {
			let that = this;
			getAddressList().then(res => {
				that.$set(that, 'addressList', res.data);
			});
		},
		getCartInfo() {
			let that = this;
			const cartIds = that.$route.params.id;
			if (!cartIds) {
				that.$dialog.error('参数有误');
				return that.$router.go(-1);
			}
			orderConfirm(cartIds)
				.then(res => {
					that.orderGroupInfo = res.data;
					that.usableCoupon = res.data.usableCoupon || {};
				})
				.catch(() => {
					that.$dialog.error('加载订单数据失败');
				});
		},
		paymentTap: function() {
			var that = this;
			if (!that.defaltAddressId) return that.$dialog.toast({ mes: '请选择收货地址' });
			createOrder(that.orderGroupInfo.orderKey, {
				addressId: that.defaltAddressId,
				useIntegral: that.useIntegral ? 1 : 0,
				couponId: that.usableCoupon.id || 0,
				payType: that.payType,
				seckill_id: that.orderGroupInfo.seckill_id,
				combinationId: that.orderGroupInfo.combination_id,
				bargainId: that.orderGroupInfo.bargain_id,
				mark: that.mark || ''
			})
				.then(res => {
					that.$dialog.loading.close();
					that.$router.push({ path: '/payment/', query: { orderId: res.data.result.orderId } });
					// const data = res.data;
					// let url = '/order/status/' + data.result.orderId;
					// switch (data.status) {
					// 	case 'ORDER_EXIST':
					// 	case 'EXTEND_ORDER':
					// 	case 'PAY_DEFICIENCY':
					// 	case 'PAY_ERROR':
					// 		this.$dialog.toast({ mes: res.msg });
					// 		this.$router.replace({
					// 			path: url + '/0?msg=' + res.msg
					// 		});
					// 		break;
					// 	case 'SUCCESS':
					// 		this.$dialog.success(res.msg);
					// 		this.$router.replace({
					// 			path: url + '/1'
					// 		});
					// 		break;
					// 	case 'WECHAT_H5_PAY':
					// 		this.$router.replace({
					// 			path: url + '/2'
					// 		});
					// 		setTimeout(() => {
					// 			location.href = data.result.jsConfig.mweb_url;
					// 		}, 100);
					// 		break;
					// 	case 'WECHAT_PAY':
					// 		pay(data.result.jsConfig).finally(() => {
					// 			this.$router.replace({
					// 				path: url + '/4'
					// 			});
					// 		});
					// }
				})
				.catch(err => {
					that.$dialog.error(err.msg || '创建订单失败');
					that.$dialog.loading.close();
					that.$router.go(-1);
				});
		}
	}
};
</script>

<style lang="less" scoped>
.address_box {
	width: 1232px;
	margin: 20px auto;
	font-size: 14px;
	.already {
		display: flex;
		.defaltAddress {
			width: 300px;
			margin-right: 30px;
			.font-color {
				color: #f60;
			}
		}
	}
	.box-card {
		.title {
			font-size: 16px;
			font-weight: 600;
		}
		.addAddress {
			text-align: center;
			cursor: pointer;
			background-color: #f60;
			width: 180px;
			height: 36px;
			line-height: 36px;
			border-radius: 4px;
			color: #fff;
		}
		.addAddress:hover {
			background-color: #ff8533;
		}
	}
	.orderGoods {
		margin-top: 20px;
		.mark {
			display: flex;
			justify-content: space-between;
			color: #888;
			margin: 20px 0;
			textarea {
				flex: 1;
				outline: none;
				margin-left: 15px;
				border-color: #ebeef5;
				padding: 10px;
			}
		}
		.btn-box {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			margin-top: 20px;
			div.section {
				color: #888;
				font-size: 12px;
				margin: 4px 0;
			}
			div.total {
				font-size: 16px;
				margin: 10px 0;
				span {
					color: #f60;
				}
			}
		}
		.goods {
			display: flex;
			img {
				width: 88px;
			}
			.content {
				margin-left: 10px;
				.dot {
					margin-left: 0;
				}
				.attr {
					display: flex;
					align-items: center;
					color: #888;
					font-size: 12px;
					span {
						margin-right: 20px;
					}
				}
			}
		}
	}
	.addressDialog {
		.newBtn {
			position: absolute;
			top: 10px;
			right: 100px;
			color: #fff;
			border-color: #f60;
			background-color: #f60;
		}
		.newBtn:hover {
			background-color: #ff8533;
		}
		.listTable {
			.operate {
				margin: 0 10px;
				cursor: pointer;
			}
			.del {
				color: #f60;
			}
		}
	}
}
</style>
